import React, { useEffect, useState } from "react";
import Topbar from "../global/Topbar";
import { Navigate, Outlet, useNavigate, useLocation } from "react-router-dom";
import AppSidebar from "../global/AppSidebar";
import { useOktaAuth } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import API from "../../Api";
const SecuredShell = () => {
  const [isSidebar, setIsSidebar] = useState(true);
  const { authState, oktaAuth } = useOktaAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log("useEffect called");
    console.log(location.pathname);
    if (!authState || !authState.isAuthenticated) {
      return;
    } else {
      if (!authState?.isAuthenticated) {
        const originalUri = toRelativeUrl(window.location.href, window.location.origin);
        console.log("okta originalUri", originalUri);
        oktaAuth.setOriginalUri(originalUri);
        oktaAuth.signInWithRedirect();
      }
    }
  }, [oktaAuth]);

  // if (!authState || !authState?.isAuthenticated) {
  //   // return <div>Loading ... </div>;
  //   // navigate("/login");
  //   console.log("returing from secured ");
  //   return <Navigate to={{ pathname: "/login" }} />;
  // }
  API.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("okta-token");

  API.interceptors.request.use(
    (config) => {
      console.log("inceterceptor request");
      // config.headers['Content-Type'] = 'application/json';
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  API.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      const originalRequest = error.config;
      console.log("error in api call", error);
      if (error.response.status === 403) {
        localStorage.removeItem("okta-token");
        localStorage.removeItem("user-info");
        oktaAuth.signOut();
        return Promise.reject(error);
      }

      return Promise.reject(error);
    }
  );

  return (
    <>
      <div className="app">
        <AppSidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default SecuredShell;

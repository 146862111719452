import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Modal,
  Skeleton,
  Snackbar,
  Stack,
  TableContainer,
  Typography,
  Icon,
  CircularProgress,
  Backdrop,
  Fab,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { states } from "../../data/state-codes";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import GaugeChart from "react-gauge-chart";

import AmountChip from "../../components/AmountChip";
import API from "../../Api";
import { CopyToClipboard } from "react-copy-to-clipboard";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Line from "../../components/Line";
import parse from "html-react-parser";
import AddIcon from "@mui/icons-material/Add";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { set } from "lodash";
import RegDetails from "../../components/RegDetails";

const Runs = () => {
  //let { pstate, pruntime } = useParams(); // Unpacking and retrieve url params
  const [searchParams, setSearchParams] = useSearchParams();

  const pstate = searchParams.get("state");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(pstate || "CA");
  const [sourceKey, setSourceKey] = useState("RTS");
  const [sources, setSources] = useState(["RTS"]);
  const [cards, setCards] = useState({ data: [] });
  const [runtime, setRuntime] = useState("");
  const [summary, setSummary] = useState({});
  const [genAI, setGenAI] = useState({});
  const [runtimes, setRuntimes] = useState([]);
  const [trendingData, setTrendingData] = useState([]);
  const [excludeSynthetic, setExcludeSynthetic] = useState(false);
  const [allRuntimes, setAllRuntimes] = useState(false);

  const [gauge, setGauge] = useState({
    RTS: { TAX: 0, FEES: 0 },
    PS: { TAX: 0, FEES: 0 },
  });

  const [openModal, setOpenModal] = useState(false);
  const [openGenaiModal, setOpenGenaiModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [copied, setCopied] = useState(false);
  const [alertContent, setAlertContent] = useState(null);
  const [severity, setSeverity] = useState("error");
  const [showCarl, setShowCarl] = useState(true);
  const [transMap, setTransMap] = useState({});

  const filterSynthetic = async ($event) => {
    console.log("called filterSynthetic", $event.target.checked);
    setExcludeSynthetic($event.target.checked);
    let filtered = allRuntimes;
    if ($event.target.checked === true) {
      filtered = allRuntimes.filter((r) => r.Synthetic !== true);
    }
    setRuntimes(filtered);
    if (filtered.length > 0) {
      setRuntime(filtered[0].Runtime);
    }
  };

  const handleOpenModal = async (title, transactionId, type) => {
    try {
      let data = transMap[transactionId];
      if (!data) {
        showProgress();
        const r1 = await API.get(`/transaction-history/${sourceKey}?tnxid=${transactionId}&runtime=${runtime}`);
        data = r1.data;

        transMap[transactionId] = data;
        setTransMap({ ...transMap });
      }
      setmodalContent({
        title: title,
        data: data ? data[type] : {},
      });
      setOpenModal(true);
      setCopied(false);
    } catch (error) {
      setAlertContent("Unable to fetch data");
      setOpenAlert(true);
      setTimeout(() => {
        setOpenAlert(false);
      }, 10000);
    } finally {
      hideProgress();
    }
  };
  const handleCloseModal = () => setOpenModal(false);

  const [open, setOpen] = useState(false);

  const showProgress = () => {
    setOpen(true);
  };
  const hideProgress = () => {
    setOpen(false);
  };

  const [modalContent, setmodalContent] = useState({
    title: "Title",
    data: {},
  });

  //gen ai modal
  const handleOpenGenaiModal = async (title, data) => {
    try {
      // console.log(data);
      setmodalContent({
        title: title,
        data: data,
      });
      setOpenGenaiModal(true);
    } catch (error) {}
  };

  const handleCloseGenaiModal = () => setOpenGenaiModal(false);

  useEffect(() => {
    console.log("called useEffect");
    loadStateData(state);
  }, []);

  useEffect(() => {
    console.log("called useEffect when runtimes change", runtimes);
    renderTrends();
  }, [runtimes]);

  const loadAlRuntimes = async (selectedState) => {
    //const sourceKey = "RTS";
    const stateKey = selectedState;
  };

  const reset = () => {
    setGauge({
      RTS: { TAX: 0, FEES: 0 },
      PS: { TAX: 0, FEES: 0 },
    });
    setRuntime("");
  };
  const loadStateData = async (selectedState) => {
    console.log("selected state", selectedState);

    setState(selectedState);
    setSearchParams({ ["source"]: sourceKey, ["state"]: selectedState });
    reset();
    try {
      // setLoading(true);
      showProgress();
      // const r = await API.get(`/assets/data/${selectedState}-data-formatted.json`);

      //  const sourceKey = "RTS";
      const stateKey = selectedState;

      const allruntimes = await API.get(`/runtime/${sourceKey}/${stateKey}/all`);
      console.log("all runtimes ", allruntimes.data);
      if (allruntimes.data) {
        setAllRuntimes(allruntimes.data);
        //let filtered = allruntimes.data.filter((m) => m.Synthetic === excludeSynthetic);
        setRuntimes(allruntimes.data);
        //renderTrends();
      }

      const rt = await API.get(`/runtime/${sourceKey}/${stateKey}/latest`);

      if (rt.data) {
        const latestRT = rt.data.Runtime;
        await loadTransactions(selectedState, latestRT, allruntimes.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      //setLoading(false);
      hideProgress();
    }
  };

  const renderTrends = () => {
    console.log("called render trends", runtimes);
    const trends = [];
    const ledends = [""];
    const dataMap = {
      "RTS.TAX": [],
      "RTS.FEES": [],
      "PS.TAX": [],
      "PS.FEES": [],
    };
    runtimes.forEach((m) => {
      const dt = new Date(m.Runtime).toLocaleString();
      dataMap["RTS.TAX"].unshift({
        x: dt,
        y: m.Summary.accuracy.RTS.TAX <= 0 ? 0 : (m.Summary.accuracy.RTS.TAX * 100).toFixed(2),
      });
      dataMap["RTS.FEES"].unshift({
        x: dt,
        y: m.Summary.accuracy.RTS.FEES <= 0 ? 0 : (m.Summary.accuracy.RTS.FEES * 100).toFixed(2),
      });
      dataMap["PS.TAX"].unshift({
        x: dt,
        y: m.Summary.accuracy.PS.TAX <= 0 ? 0 : (m.Summary.accuracy.PS.TAX * 100).toFixed(2),
      });
      dataMap["PS.FEES"].unshift({
        x: dt,
        y: m.Summary.accuracy.PS.FEES <= 0 ? 0 : (m.Summary.accuracy.PS.FEES * 100).toFixed(2),
      });

      if (showCarl && m.Summary.accuracy.CARL) {
        dataMap["CARL.TAX"] = dataMap["CARL.TAX"] || [];
        dataMap["CARL.FEES"] = dataMap["CARL.FEES"] || [];

        dataMap["CARL.TAX"].unshift({
          x: dt,
          y: m.Summary.accuracy.CARL.TAX <= 0 ? 0 : (m.Summary.accuracy.CARL.TAX * 100).toFixed(2),
        });
        dataMap["CARL.FEES"].unshift({
          x: dt,
          y: m.Summary.accuracy.CARL.FEES <= 0 ? 0 : (m.Summary.accuracy.CARL.FEES * 100).toFixed(2),
        });
      }
    });

    Object.keys(dataMap).forEach((key) => {
      trends.push({
        id: key,
        data: dataMap[key],
      });
    });

    setTrendingData(trends);
  };

  const loadTransactions = async (state, runtime, argruntimes) => {
    console.log("loading transactions ", state, runtime, runtimes);
    argruntimes = argruntimes || runtimes;
    //setSearchParams({ ["runtime"]: runtime });
    console.log("searchParams", searchParams);
    setSearchParams({ ["source"]: sourceKey, ["state"]: state, ["runtime"]: runtime });
    const idx = argruntimes.findIndex((m) => m.Runtime === runtime);
    setRuntime(runtime);
    console.log("selected index", idx);
    if (idx !== -1) {
      const summary = argruntimes[idx].Summary;
      summary.sourceToCompare = summary.sourceToCompare || "DMV";
      const genAI = argruntimes[idx].GenAI || { genai: summary.genai, report: summary.report };
      console.log("genAI", genAI);
      console.log("Summary", summary);
      setGauge(summary.accuracy);
      setSummary(summary);
      setGenAI(genAI);
    }

    try {
      //showProgress();
      hideProgress();
      const r = await API.get(`/transactions/${sourceKey}/${state}/${runtime}?light=true`);

      setLoading(false);
      console.log(r.data);

      console.log("set the cards");
      setCards({ data: r.data });
    } catch (error) {
      console.log(error);
      setCards({ data: [] });
      setGauge({
        RTS: { TAX: 0, FEES: 0 },
        PS: { TAX: 0, FEES: 0 },
      });
    } finally {
      hideProgress();
    }
  };

  const rerun = async (sourceKey, runtime) => {
    console.log("rerun with ", sourceKey, runtime);

    try {
      showProgress();

      const r = await API.post(`/reupload`, { source: sourceKey, rtid: runtime });

      console.log(r.data);
      setSeverity("info");
      setAlertContent("Processing started. Check the events.");
      setOpenAlert(true);
      setTimeout(() => {
        setSeverity("error");
        setOpenAlert(false);
      }, 10000);
    } catch (error) {
    } finally {
      hideProgress();
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colors.grey[800],
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const [expanded, setExpanded] = useState(true);

  return (
    <Box m="10px">
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} onClick={hideProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={1}>
        <Grid item md={4} xl={2}>
          <FormControl sx={{ m: 1, minWidth: 200 }}>
            <InputLabel id="demo-simple-select-helper-label">Source</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={sourceKey}
              label="State"
              onChange={async (e) => {
                setSourceKey(e.target.value);
              }}
            >
              {sources.map((m) => (
                <MenuItem key={m} value={m}>
                  {m}
                </MenuItem>
              ))}
            </Select>
            {/* <FormHelperText>Select the state</FormHelperText> */}
          </FormControl>
        </Grid>

        <Grid item md={4} xl={2}>
          <FormControl sx={{ m: 1, minWidth: 200 }}>
            <InputLabel id="demo-simple-select-helper-label">State</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={state}
              label="State"
              onChange={async (e) => {
                loadStateData(e.target.value);
              }}
            >
              {states.map((m) => (
                <MenuItem key={m.code} value={m.code}>
                  {m.label}
                </MenuItem>
              ))}
            </Select>
            {/* <FormHelperText>Select the state</FormHelperText> */}
          </FormControl>
        </Grid>

        <Grid item md={4} xl={3}>
          <FormControl sx={{ m: 1, minWidth: 200 }}>
            <InputLabel id="demo-simple-select-helper-label">Runtime</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={runtime}
              label="Runtime"
              onChange={async (e) => {
                //loadStateData(e.target.value);
                loadTransactions(state, e.target.value);
              }}
            >
              {runtimes.map((m) => (
                <MenuItem key={m.Runtime} value={m.Runtime}>
                  {m.Runtime} ({new Date(m.Runtime).toLocaleString()})
                </MenuItem>
              ))}
            </Select>
            {/* <FormHelperText>Select the state</FormHelperText> */}
          </FormControl>
        </Grid>

        <Grid item md={4} xl={2}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox color="secondary" checked={excludeSynthetic} onChange={filterSynthetic} />}
              label="Exclude Synthetic Data"
            />
          </FormGroup>
        </Grid>

        <Grid item md={4} xl={3}>
          {sourceKey && runtime ? (
            <Button variant="contained" sx={{ mt: 2 }} color="secondary" onClick={() => rerun(sourceKey, runtime)}>
              Re-Process this file
            </Button>
          ) : null}
        </Grid>
      </Grid>
      {summary && (
        <Stack direction="column" justifyContent="space-evenly" alignItems="flex-start" spacing={2} sx={{ mt: 10 }}>
          <Stack direction="row">
            <Stack direction={"column"}>
              <GaugeChart id="gauge-rts-tax" nrOfLevels={20} percent={gauge.RTS.TAX} colors={["#FF0000", "#00FF00"]} />
              <Typography variant="h5" align="center">
                RTS Tax Accuracy
              </Typography>
            </Stack>

            <Stack direction={"column"}>
              <GaugeChart
                id="gauge-rts-fees"
                nrOfLevels={20}
                percent={gauge.RTS.FEES}
                colors={["#FF0000", "#00FF00"]}
              />
              <Typography variant="h5" align="center">
                RTS Fees Accuracy
              </Typography>
            </Stack>
            <Stack direction={"column"}>
              <GaugeChart id="gauge-ps-tax" nrOfLevels={20} percent={gauge.PS.TAX} colors={["#FF0000", "#00FF00"]} />
              <Typography variant="h5" align="center">
                PS Tax Accuracy
              </Typography>
            </Stack>

            <Stack direction={"column"}>
              <GaugeChart id="gauge-ps-fees" nrOfLevels={20} percent={gauge.PS.FEES} colors={["#FF0000", "#00FF00"]} />
              <Typography variant="h5" align="center">
                PS Fees Accuracy
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2} alignItems={"center"} alignContent={"center"}>
            {showCarl && gauge.CARL ? (
              <>
                <Stack direction={"column"}>
                  <GaugeChart
                    id="gauge-ps-tax"
                    nrOfLevels={20}
                    percent={gauge.CARL.TAX}
                    colors={["#FF0000", "#00FF00"]}
                  />
                  <Typography variant="h5" align="center">
                    CARL Tax Accuracy
                  </Typography>
                </Stack>

                <Stack direction={"column"}>
                  <GaugeChart
                    id="gauge-ps-fees"
                    nrOfLevels={20}
                    percent={gauge.CARL.FEES}
                    colors={["#FF0000", "#00FF00"]}
                  />
                  <Typography variant="h5" align="center">
                    CARL Fees Accuracy
                  </Typography>
                </Stack>
              </>
            ) : null}
            <Line data={trendingData} height={"300px"} width={"600px"} legendX={"Time"} legendY={"Accuracy"}></Line>
            {!showCarl ? (
              <Card
                sx={{
                  marginTop: 5,
                  marginLeft: 10,
                  marginRight: 10,
                  borderRadius: 2,
                  padding: 5,
                  backgroundColor: `${colors.primary[400]} !important`,
                }}
              >
                <CardContent>
                  <Box>
                    <Typography variant="h4" color="text.secondary">
                      State: {state}
                    </Typography>
                    <Typography variant="h4" color="text.secondary">
                      Run ID: {runtime}
                    </Typography>
                    <Typography variant="h4" color="text.secondary" sx={{ mt: 2 }}>
                      Total transactions: {summary.total}
                    </Typography>
                    {summary.total ? (
                      <Typography variant="h4" color="text.secondary" sx={{ mt: 2 }}>
                        This is a synthetic data. Compared against {summary.sourceToCompare}
                      </Typography>
                    ) : null}

                    <Typography variant="h4" color="text.secondary">
                      Successful: {summary.successful}
                    </Typography>
                    <Typography variant="h4" color="text.secondary">
                      Failed: {summary.failed}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            ) : null}
          </Stack>
        </Stack>
      )}

      {cards.data.length > 0 && (
        <Stack direction={"column"} spacing={2} alignItems={"center"} alignContent={"center"} sx={{ mt: 10 }}>
          {summary.synthetic ? (
            <Typography variant="h3" sx={{ mt: 2 }}>
              ⚠️ This is a synthetic data. Compared against {summary.sourceToCompare}.
            </Typography>
          ) : null}

          <Typography variant="p" align="center">
            Taxes and Fees comparison for {state} with Runtime Id {runtime}
          </Typography>
          <Typography variant="p" align="center">
            Total transactions: {summary.total} Successul: {summary.successful} Failed: {summary.failed}
          </Typography>

          {genAI.genai && genAI.genai.length > 0 && (
            <Fab
              color="secondary"
              aria-label="add"
              sx={{ position: "fixed", bottom: 20, right: 20 }}
              onClick={() => handleOpenGenaiModal(genAI.genai)}
            >
              <AutoAwesomeIcon />
            </Fab>
          )}
        </Stack>
      )}

      <Box m={5}>
        {cards &&
          cards.data.map((m, i) => (
            <Card
              key={i}
              sx={{
                marginTop: 5,
                borderRadius: 2,
                backgroundColor: `${colors.primary[400]} !important`,
              }}
            >
              <CardContent>
                {loading ? (
                  <Box>
                    <Skeleton sx={{ height: 100 }} animation="wave" variant="rectangular" />
                    <Skeleton sx={{ height: 100, mt: 5 }} animation="wave" variant="rectangular" />
                  </Box>
                ) : (
                  <>
                    <TableContainer>
                      <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        {m.Snapshot.STATUS === "SUCCESS" ? (
                          <TableHead>
                            <TableRow color="red">
                              <StyledTableCell>
                                <CheckCircleIcon color="success"></CheckCircleIcon>
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <Typography variant="h3" component="div">
                                  DMV
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <Typography variant="h3" component="div">
                                  RTS
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <Typography variant="h3" component="div">
                                  PS
                                </Typography>
                              </StyledTableCell>
                              {showCarl ? (
                                <StyledTableCell align="right">
                                  <Typography variant="h3" component="div">
                                    CARL
                                  </Typography>
                                </StyledTableCell>
                              ) : null}
                            </TableRow>
                          </TableHead>
                        ) : (
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>
                                {" "}
                                <UnpublishedIcon color="error"></UnpublishedIcon>
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                        )}

                        {m.Snapshot.STATUS === "SUCCESS" ? (
                          <TableBody>
                            <StyledTableRow>
                              <StyledTableCell component="th" scope="row">
                                {" "}
                                TOTAL TAX
                              </StyledTableCell>
                              <StyledTableCell align="right">{m.Snapshot.TOTAL_TAX.DMV}</StyledTableCell>
                              <StyledTableCell align="right">
                                <AmountChip
                                  actualAmount={m.Snapshot.TOTAL_TAX.RTS}
                                  expectedAmount={
                                    summary.sourceToCompare === "DMV"
                                      ? m.Snapshot.TOTAL_TAX.DMV
                                      : summary.sourceToCompare === "TNF"
                                      ? m.Snapshot.TOTAL_TAX.RTS
                                      : summary.sourceToCompare === "PS"
                                      ? m.Snapshot.TOTAL_TAX.PS
                                      : m.Snapshot.TOTAL_TAX.CARL
                                  }
                                ></AmountChip>
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <AmountChip
                                  actualAmount={m.Snapshot.TOTAL_TAX.PS}
                                  expectedAmount={
                                    summary.sourceToCompare === "DMV"
                                      ? m.Snapshot.TOTAL_TAX.DMV
                                      : summary.sourceToCompare === "TNF"
                                      ? m.Snapshot.TOTAL_TAX.RTS
                                      : summary.sourceToCompare === "PS"
                                      ? m.Snapshot.TOTAL_TAX.PS
                                      : m.Snapshot.TOTAL_TAX.CARL
                                  }
                                ></AmountChip>
                              </StyledTableCell>

                              {showCarl ? (
                                <StyledTableCell align="right">
                                  {m.Snapshot.TOTAL_TAX.CARL ? (
                                    <AmountChip
                                      actualAmount={m.Snapshot.TOTAL_TAX.CARL}
                                      expectedAmount={
                                        summary.sourceToCompare === "DMV"
                                          ? m.Snapshot.TOTAL_TAX.DMV
                                          : summary.sourceToCompare === "TNF"
                                          ? m.Snapshot.TOTAL_TAX.RTS
                                          : summary.sourceToCompare === "PS"
                                          ? m.Snapshot.TOTAL_TAX.PS
                                          : m.Snapshot.TOTAL_TAX.CARL
                                      }
                                    ></AmountChip>
                                  ) : null}
                                </StyledTableCell>
                              ) : null}
                            </StyledTableRow>

                            {expanded && (
                              <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                  {" "}
                                  TAX BREAKDOWN
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  <Stack>
                                    {m.Snapshot.TAX_BREAKDOWN.DMV.map(
                                      (f, i) =>
                                        f.amount && (
                                          <span key={i}>
                                            {f.description}: {f.amount || "0.00"}
                                          </span>
                                        )
                                    )}
                                  </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  <Stack>
                                    {m.Snapshot.TAX_BREAKDOWN.RTS.map(
                                      (f, i) =>
                                        f.amount && (
                                          <span key={i}>
                                            {f.description}: {f.amount || "0.00"}
                                          </span>
                                        )
                                    )}
                                  </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  <Stack>
                                    {m.Snapshot.TAX_BREAKDOWN.PS.map(
                                      (f, i) =>
                                        f.amount && (
                                          <span key={i}>
                                            {f.description}: {f.amount || "0.00"}
                                          </span>
                                        )
                                    )}
                                  </Stack>
                                </StyledTableCell>

                                {showCarl ? (
                                  <StyledTableCell align="right">
                                    <Stack>
                                      {(m.Snapshot.TAX_BREAKDOWN.CARL || []).map(
                                        (f, i) =>
                                          f.amount && (
                                            <span key={i}>
                                              {f.description}: {f.amount || "0.00"}
                                            </span>
                                          )
                                      )}
                                    </Stack>
                                  </StyledTableCell>
                                ) : null}
                              </StyledTableRow>
                            )}

                            <StyledTableRow>
                              <StyledTableCell component="th" scope="row">
                                {" "}
                                TOTAL FEES
                              </StyledTableCell>
                              <StyledTableCell align="right">{m.Snapshot.TOTAL_FEES.DMV}</StyledTableCell>
                              <StyledTableCell align="right">
                                <AmountChip
                                  actualAmount={m.Snapshot.TOTAL_FEES.RTS}
                                  expectedAmount={
                                    summary.sourceToCompare === "DMV"
                                      ? m.Snapshot.TOTAL_FEES.DMV
                                      : summary.sourceToCompare === "TNF"
                                      ? m.Snapshot.TOTAL_FEES.RTS
                                      : summary.sourceToCompare === "PS"
                                      ? m.Snapshot.TOTAL_FEES.PS
                                      : m.Snapshot.TOTAL_FEES.CARL
                                  }
                                ></AmountChip>
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <AmountChip
                                  actualAmount={m.Snapshot.TOTAL_FEES.PS}
                                  expectedAmount={
                                    summary.sourceToCompare === "DMV"
                                      ? m.Snapshot.TOTAL_FEES.DMV
                                      : summary.sourceToCompare === "TNF"
                                      ? m.Snapshot.TOTAL_FEES.RTS
                                      : summary.sourceToCompare === "PS"
                                      ? m.Snapshot.TOTAL_FEES.PS
                                      : m.Snapshot.TOTAL_FEES.CARL
                                  }
                                ></AmountChip>
                              </StyledTableCell>
                              {showCarl ? (
                                <StyledTableCell align="right">
                                  {m.Snapshot.TOTAL_FEES.CARL ? (
                                    <AmountChip
                                      actualAmount={m.Snapshot.TOTAL_FEES.CARL}
                                      expectedAmount={
                                        summary.sourceToCompare === "DMV"
                                          ? m.Snapshot.TOTAL_FEES.DMV
                                          : summary.sourceToCompare === "TNF"
                                          ? m.Snapshot.TOTAL_FEES.RTS
                                          : summary.sourceToCompare === "PS"
                                          ? m.Snapshot.TOTAL_FEES.PS
                                          : m.Snapshot.TOTAL_FEES.CARL
                                      }
                                    ></AmountChip>
                                  ) : null}
                                </StyledTableCell>
                              ) : null}
                            </StyledTableRow>

                            <StyledTableRow>
                              <StyledTableCell component="th" scope="row">
                                {" "}
                                FEES BREAKDOWN
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <Stack>
                                  {m.Snapshot.FEES_BREAKDOWN.DMV.map((f, i) => (
                                    <span key={i}>
                                      {f.description}: {f.amount || "0.00"}
                                    </span>
                                  ))}
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <Stack>
                                  {m.Snapshot.FEES_BREAKDOWN.RTS.map((f, i) => (
                                    <span key={i}>
                                      {f.description}: {f.amount || "0.00"}
                                    </span>
                                  ))}
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <Stack>
                                  {m.Snapshot.FEES_BREAKDOWN.PS.map((f, i) => (
                                    <span key={i}>
                                      {f.description}: {f.amount || "0.00"}
                                    </span>
                                  ))}
                                </Stack>
                              </StyledTableCell>
                              {showCarl ? (
                                <StyledTableCell align="right">
                                  <Stack>
                                    {(m.Snapshot.FEES_BREAKDOWN.CARL || []).map((f, i) => (
                                      <span key={i}>
                                        {f.description}: {f.amount || "0.00"}
                                      </span>
                                    ))}
                                  </Stack>
                                </StyledTableCell>
                              ) : null}
                            </StyledTableRow>
                          </TableBody>
                        ) : (
                          <TableBody>
                            <StyledTableRow>
                              <StyledTableCell>{m.Snapshot.ERRORS}</StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                    <RegDetails metadata={m.TnxMetadata}></RegDetails>
                  </>
                )}
              </CardContent>
              <CardActions>
                <Button
                  variant="outlined"
                  color="success"
                  onClick={() => handleOpenModal("DMV Request", m.TransactionID, "SourceRequest")}
                >
                  View DMV Log
                </Button>
                <Button
                  variant="outlined"
                  color="success"
                  onClick={() => handleOpenModal("RTS Request", m.TransactionID, "RTSRequest")}
                >
                  View TNF Request
                </Button>
                <Button
                  variant="outlined"
                  color="success"
                  onClick={() => handleOpenModal("RTS Response", m.TransactionID, "RTSResponse")}
                >
                  View TNF Response
                </Button>
                <Button
                  variant="outlined"
                  color="success"
                  onClick={() => handleOpenModal("PS Request", m.TransactionID, "PSRequest")}
                >
                  View PS Request
                </Button>
                <Button
                  variant="outlined"
                  color="success"
                  onClick={() => handleOpenModal("PS Response", m.TransactionID, "PSResponse")}
                >
                  View PS Response
                </Button>

                {showCarl ? (
                  <>
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={() => handleOpenModal("Carleton Request", m.TransactionID, "CARLRequest")}
                    >
                      View Carl Request
                    </Button>
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={() => handleOpenModal("Carleton Response", m.TransactionID, "CARLResponse")}
                    >
                      View Carl Response
                    </Button>
                  </>
                ) : null}
                <Typography variant="h6" component="h6" sx={{ pl: 5 }}>
                  Tnx Id: {m.TransactionID}
                </Typography>
              </CardActions>
            </Card>
          ))}
      </Box>
      <Modal
        open={openGenaiModal}
        onClose={handleCloseGenaiModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "720px",
            maxHeight: "80vh",
            bgcolor: colors.primary[400],

            border: "2px solid #000",
            overflowY: "scroll",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h3" component="h2" sx={{ mb: 3 }}>
            ✨ Executive Summary
          </Typography>
          <b>Fees:</b><br></br><br></br>
          <div>{parse(genAI.genai || "")}</div>
          <br></br><b>Taxes:</b><br></br><br></br>
          <div>{parse(genAI.genaiTax || "")}</div>
          <Typography id="modal-modal-title" variant="h3" component="h2" sx={{ mb: 3, mt: 5 }}>
            ✨ Detailed Report
          </Typography>
          <b>Fees:</b><br></br><br></br>
          <div>{parse(genAI.report || "")}</div>
          <br></br><b>Taxes:</b><br></br><br></br>
          <div>{parse(genAI.taxesReport || "")}</div>
        </Box>
      </Modal>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "600px",

            maxHeight: "80vh",
            bgcolor: "background.paper",
            border: "2px solid #000",
            overflowY: "scroll",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {modalContent.title}
          </Typography>

          <Button variant="outlined" color="error" onClick={handleCloseModal}>
            Close
          </Button>
          <CopyToClipboard
            sx={{ ml: "5px" }}
            text={JSON.stringify(modalContent.data, null, 2)}
            onCopy={() => setCopied(true)}
          >
            <Button variant="outlined" color="success">
              Copy
            </Button>
          </CopyToClipboard>
          {copied ? (
            <Typography variant="span" color={"green"} component="span" sx={{ ml: "5px" }}>
              Copied.
            </Typography>
          ) : null}
          {/* <Button variant="outlined" color="success" onClick={handleCloseModal}>
            Copy
          </Button> */}
          <pre>{JSON.stringify(modalContent.data, null, 2)}</pre>
          <Button variant="outlined" color="error" onClick={handleCloseModal}>
            Close
          </Button>
        </Box>
      </Modal>

      <Snackbar open={openAlert} autoHideDuration={6000} onClose={() => setOpenAlert(false)}>
        <Alert onClose={() => setOpenAlert(false)} severity={severity} sx={{ width: "300px" }}>
          {alertContent}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Runs;

import { useTheme } from "@emotion/react";
import {
  Box,
  Typography,
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Modal,
  Skeleton,
  Snackbar,
  Stack,
  TableContainer,
  Icon,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { tokens } from "../../theme";
import AmountChip from "../../components/AmountChip";
import API from "../../Api";
import { CopyToClipboard } from "react-copy-to-clipboard";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

//TNX#TS#1695931279611#STATE#IN#ID#1719825091

export default function Transactions() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [transaction, setTransaction] = useState(null);
  const [rerunTransaction, setRerunTransaction] = useState(null);
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const psource = "RTS";
  const pstate = searchParams.get("state");
  const ptnxid = searchParams.get("tnxid");
  const pruntime = searchParams.get("runtime");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);

  const showProgress = () => {
    setOpen(true);
  };
  const hideProgress = () => {
    setOpen(false);
  };

  useEffect(() => {
    console.log("Get transaction histroy for ", ptnxid, pruntime);
    loadSingleTransaction();
  }, []);

  const loadSingleTransaction = async () => {
    try {
      showProgress();
      const r1 = await API.get(`/transaction-history/${psource}?tnxid=${ptnxid}&runtime=${pruntime}`);
      const r2 = await API.get(`/transaction-history/${psource}?tnxid=${ptnxid}`);
      console.log(r2.data);
      setTransaction(r1.data);

      if (r2.data.length > 1) {
        let indx = r2.data.findIndex((m) => m.SK === r1.data.SK);
        console.log("index", indx);
        let data = r2.data;
        data.splice(indx, 1);
        console.log("history", data);
        setHistory(data);
      }
    } catch (error) {
    } finally {
      hideProgress();
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colors.grey[800],
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const [expanded, setExpanded] = useState(true);

  const handleOpenModal = async (title, data) => {
    try {
    } catch (error) {}
  };

  const rerun = async (transaction) => {
    console.log("rerun with ", transaction);
    const s1 = transaction.GSI1SK.split("#");
    const tnxid = s1[2];
    const rtid = s1[4];
    const source = transaction.GSI1PK.split("#")[1];
    console.log(source, tnxid, rtid);

    try {
      showProgress();
      const r = await API.post(`/transaction-rerun`, { source, tnxid, rtid });
      console.log(r.data);
      setRerunTransaction(r.data);
    } catch (error) {
    } finally {
      hideProgress();
    }
  };

  return (
    <Box m="10px">
      <Typography variant="h5" align="center">
        Transaction details
      </Typography>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} onClick={hideProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {transaction && (
        <Card
          sx={{
            marginTop: 5,
            marginLeft: 10,
            marginRight: 10,
            borderRadius: 2,
            backgroundColor: `${colors.primary[400]} !important`,
          }}
        >
          <CardContent>
            <Stack direction={"row"} spacing={2}>
              <Box>
                <Typography variant="h5" color="text.secondary" gutterBottom>
                  Batch runtime {new Date(parseInt(pruntime)).toLocaleString()}
                </Typography>
                <Typography variant="h5" color="text.secondary" gutterBottom>
                  {transaction.GSI1SK}
                </Typography>
                <Typography variant="h2" component="div">
                  {pstate}
                </Typography>
                <Typography sx={{ mb: 1 }} variant="h3" color="text.secondary">
                  {transaction.SourceRequest.client.client_name}
                </Typography>
                <Typography sx={{ mb: 1.5 }} variant="h3" color="text.secondary">
                  {transaction.SourceRequest.client.client_city}, {transaction.SourceRequest.client.client_zip}
                </Typography>
              </Box>
              <Box>
                <Typography variant="h3" color="text.secondary"></Typography>
                <Typography variant="h3" color="text.secondary">
                  {transaction.SourceRequest.vehicle.vin}
                </Typography>
                <Typography variant="h3" color="text.secondary">
                  {transaction.SourceRequest.vehicle.model_year}, {transaction.SourceRequest.vehicle.fuel_type}
                </Typography>
                <Typography variant="h3" color="text.secondary">
                  {transaction.SourceRequest.vehicle.vehicle_class_code},{" "}
                  {transaction.SourceRequest.vehicle.weight_gross}lb
                </Typography>
                <Button variant="contained" sx={{ mt: 2 }} color="secondary" onClick={() => rerun(transaction)}>
                  Re-Run
                </Button>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      )}

      {rerunTransaction && (
        <Box m={5}>
          <Typography variant="h3" align="center">
            Re-ran Transaction
          </Typography>
          <Card
            sx={{
              marginTop: 5,
              borderRadius: 2,
              backgroundColor: `${colors.grey[800]} !important`,
            }}
          >
            <CardContent>
              {loading ? (
                <Box>
                  <Skeleton sx={{ height: 100 }} animation="wave" variant="rectangular" />
                  <Skeleton sx={{ height: 100, mt: 5 }} animation="wave" variant="rectangular" />
                </Box>
              ) : (
                <TableContainer>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    {rerunTransaction.Snapshot.STATUS === "SUCCESS" ? (
                      <TableHead>
                        <TableRow color="red">
                          <StyledTableCell>
                            <CheckCircleIcon color="success"></CheckCircleIcon>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <Typography variant="h3" component="div">
                              DMV
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <Typography variant="h3" component="div">
                              RTS
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <Typography variant="h3" component="div">
                              PS
                            </Typography>
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                    ) : (
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>
                            {" "}
                            <UnpublishedIcon color="error"></UnpublishedIcon>
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                    )}

                    {rerunTransaction.Snapshot.STATUS === "SUCCESS" ? (
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row">
                            {" "}
                            TOTAL TAX
                          </StyledTableCell>
                          <StyledTableCell align="right">{rerunTransaction.Snapshot.TOTAL_TAX.DMV}</StyledTableCell>
                          <StyledTableCell align="right">
                            <AmountChip
                              actualAmount={rerunTransaction.Snapshot.TOTAL_TAX.RTS}
                              expectedAmount={rerunTransaction.Snapshot.TOTAL_TAX.DMV}
                            ></AmountChip>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <AmountChip
                              actualAmount={rerunTransaction.Snapshot.TOTAL_TAX.PS}
                              expectedAmount={rerunTransaction.Snapshot.TOTAL_TAX.DMV}
                            ></AmountChip>
                          </StyledTableCell>
                        </StyledTableRow>

                        {expanded && (
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              {" "}
                              TAX BREAKDOWN
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <Stack>
                                {rerunTransaction.Snapshot.TAX_BREAKDOWN.DMV.map(
                                  (f, i) =>
                                    f.amount && (
                                      <span key={i}>
                                        {f.description}: {f.amount || "0.00"}
                                      </span>
                                    )
                                )}
                              </Stack>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <Stack>
                                {rerunTransaction.Snapshot.TAX_BREAKDOWN.RTS.map(
                                  (f, i) =>
                                    f.amount && (
                                      <span key={i}>
                                        {f.description}: {f.amount || "0.00"}
                                      </span>
                                    )
                                )}
                              </Stack>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <Stack>
                                {rerunTransaction.Snapshot.TAX_BREAKDOWN.PS.map(
                                  (f, i) =>
                                    f.amount && (
                                      <span key={i}>
                                        {f.description}: {f.amount || "0.00"}
                                      </span>
                                    )
                                )}
                              </Stack>
                            </StyledTableCell>
                          </StyledTableRow>
                        )}

                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row">
                            {" "}
                            TOTAL FEES
                          </StyledTableCell>
                          <StyledTableCell align="right">{rerunTransaction.Snapshot.TOTAL_FEES.DMV}</StyledTableCell>
                          <StyledTableCell align="right">
                            <AmountChip
                              actualAmount={rerunTransaction.Snapshot.TOTAL_FEES.RTS}
                              expectedAmount={rerunTransaction.Snapshot.TOTAL_FEES.DMV}
                            ></AmountChip>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <AmountChip
                              actualAmount={rerunTransaction.Snapshot.TOTAL_FEES.PS}
                              expectedAmount={rerunTransaction.Snapshot.TOTAL_FEES.RTS}
                            ></AmountChip>
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row">
                            {" "}
                            FEES BREAKDOWN
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <Stack>
                              {rerunTransaction.Snapshot.FEES_BREAKDOWN.DMV.map(
                                (f, i) =>
                                  f.amount && (
                                    <span key={i}>
                                      {f.description}: {f.amount || "0.00"}
                                    </span>
                                  )
                              )}
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <Stack>
                              {rerunTransaction.Snapshot.FEES_BREAKDOWN.RTS.map(
                                (f, i) =>
                                  f.amount && (
                                    <span key={i}>
                                      {f.description}: {f.amount || "0.00"}
                                    </span>
                                  )
                              )}
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <Stack>
                              {rerunTransaction.Snapshot.FEES_BREAKDOWN.PS.map(
                                (f, i) =>
                                  f.amount && (
                                    <span key={i}>
                                      {f.description}: {f.amount || "0.00"}
                                    </span>
                                  )
                              )}
                            </Stack>
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    ) : (
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell>{rerunTransaction.Snapshot.ERRORS}</StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              )}
            </CardContent>
            <CardActions>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleOpenModal("DMV Request", rerunTransaction.SourceRequest)}
              >
                View DMV Log
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleOpenModal("RTS Request", rerunTransaction.RTSRequest)}
              >
                View TNF Request
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleOpenModal("RTS Response", rerunTransaction.RTSResponse)}
              >
                View TNF Response
              </Button>

              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleOpenModal("PS Request", rerunTransaction.PSRequest)}
              >
                View PS Request
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleOpenModal("PS Response", rerunTransaction.PSResponse)}
              >
                View PS Response
              </Button>
            </CardActions>
            <CardActions>
              <Typography
                color="secondary"
                variant="span"
                component="span"
                sx={{ fontSize: "0.5rem", textAlign: "center" }}
              >
                Transaction id {rerunTransaction.TransactionID}
              </Typography>
            </CardActions>
          </Card>
        </Box>
      )}

      <Box m={5}>
        {transaction && (
          <Card
            sx={{
              marginTop: 5,
              borderRadius: 2,
              backgroundColor: `${colors.primary[400]} !important`,
            }}
          >
            <CardContent>
              {loading ? (
                <Box>
                  <Skeleton sx={{ height: 100 }} animation="wave" variant="rectangular" />
                  <Skeleton sx={{ height: 100, mt: 5 }} animation="wave" variant="rectangular" />
                </Box>
              ) : (
                <TableContainer>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    {transaction.Snapshot.STATUS === "SUCCESS" ? (
                      <TableHead>
                        <TableRow color="red">
                          <StyledTableCell>
                            <CheckCircleIcon color="success"></CheckCircleIcon>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <Typography variant="h3" component="div">
                              DMV
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <Typography variant="h3" component="div">
                              RTS
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <Typography variant="h3" component="div">
                              PS
                            </Typography>
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                    ) : (
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>
                            {" "}
                            <UnpublishedIcon color="error"></UnpublishedIcon>
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                    )}

                    {transaction.Snapshot.STATUS === "SUCCESS" ? (
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row">
                            {" "}
                            TOTAL TAX
                          </StyledTableCell>
                          <StyledTableCell align="right">{transaction.Snapshot.TOTAL_TAX.DMV}</StyledTableCell>
                          <StyledTableCell align="right">
                            <AmountChip
                              actualAmount={transaction.Snapshot.TOTAL_TAX.RTS}
                              expectedAmount={transaction.Snapshot.TOTAL_TAX.DMV}
                            ></AmountChip>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <AmountChip
                              actualAmount={transaction.Snapshot.TOTAL_TAX.PS}
                              expectedAmount={transaction.Snapshot.TOTAL_TAX.DMV}
                            ></AmountChip>
                          </StyledTableCell>
                        </StyledTableRow>

                        {expanded && (
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              {" "}
                              TAX BREAKDOWN
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <Stack>
                                {transaction.Snapshot.TAX_BREAKDOWN.DMV.map(
                                  (f, i) =>
                                    f.amount && (
                                      <span key={i}>
                                        {f.description}: {f.amount || "0.00"}
                                      </span>
                                    )
                                )}
                              </Stack>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <Stack>
                                {transaction.Snapshot.TAX_BREAKDOWN.RTS.map(
                                  (f, i) =>
                                    f.amount && (
                                      <span key={i}>
                                        {f.description}: {f.amount || "0.00"}
                                      </span>
                                    )
                                )}
                              </Stack>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <Stack>
                                {transaction.Snapshot.TAX_BREAKDOWN.PS.map(
                                  (f, i) =>
                                    f.amount && (
                                      <span key={i}>
                                        {f.description}: {f.amount || "0.00"}
                                      </span>
                                    )
                                )}
                              </Stack>
                            </StyledTableCell>
                          </StyledTableRow>
                        )}

                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row">
                            {" "}
                            TOTAL FEES
                          </StyledTableCell>
                          <StyledTableCell align="right">{transaction.Snapshot.TOTAL_FEES.DMV}</StyledTableCell>
                          <StyledTableCell align="right">
                            <AmountChip
                              actualAmount={transaction.Snapshot.TOTAL_FEES.RTS}
                              expectedAmount={transaction.Snapshot.TOTAL_FEES.DMV}
                            ></AmountChip>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <AmountChip
                              actualAmount={transaction.Snapshot.TOTAL_FEES.PS}
                              expectedAmount={transaction.Snapshot.TOTAL_FEES.RTS}
                            ></AmountChip>
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row">
                            {" "}
                            FEES BREAKDOWN
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <Stack>
                              {transaction.Snapshot.FEES_BREAKDOWN.DMV.map(
                                (f, i) =>
                                  f.amount && (
                                    <span key={i}>
                                      {f.description}: {f.amount || "0.00"}
                                    </span>
                                  )
                              )}
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <Stack>
                              {transaction.Snapshot.FEES_BREAKDOWN.RTS.map(
                                (f, i) =>
                                  f.amount && (
                                    <span key={i}>
                                      {f.description}: {f.amount || "0.00"}
                                    </span>
                                  )
                              )}
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <Stack>
                              {transaction.Snapshot.FEES_BREAKDOWN.PS.map(
                                (f, i) =>
                                  f.amount && (
                                    <span key={i}>
                                      {f.description}: {f.amount || "0.00"}
                                    </span>
                                  )
                              )}
                            </Stack>
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    ) : (
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell>{transaction.Snapshot.ERRORS}</StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              )}
            </CardContent>
            <CardActions>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleOpenModal("DMV Request", transaction.SourceRequest)}
              >
                View DMV Log
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleOpenModal("RTS Request", transaction.RTSRequest)}
              >
                View TNF Request
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleOpenModal("RTS Response", transaction.RTSResponse)}
              >
                View TNF Response
              </Button>

              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleOpenModal("PS Request", transaction.PSRequest)}
              >
                View PS Request
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleOpenModal("PS Response", transaction.PSResponse)}
              >
                View PS Response
              </Button>
            </CardActions>
            <CardActions>
              <Typography
                color="secondary"
                variant="span"
                component="span"
                sx={{ fontSize: "0.5rem", textAlign: "center" }}
              >
                Transaction id {transaction.TransactionID}
              </Typography>
            </CardActions>
          </Card>
        )}
      </Box>

      {history.length > 0 && (
        <Box>
          <Typography variant="h3" align="center">
            Transaction history
          </Typography>

          <Typography variant="h5" align="center">
            There are {history.length} runs of this transaction
          </Typography>

          {history.map((m, i) => (
            <Card
              key={i}
              sx={{
                marginTop: 5,
                borderRadius: 2,
                backgroundColor: `${colors.primary[900]} !important`,
              }}
            >
              <CardContent>
                {loading ? (
                  <Box>
                    <Skeleton sx={{ height: 100 }} animation="wave" variant="rectangular" />
                    <Skeleton sx={{ height: 100, mt: 5 }} animation="wave" variant="rectangular" />
                  </Box>
                ) : (
                  <>
                    <Typography variant="h5" align="center" sx={{ mb: 2 }}>
                      Batch runtime {new Date(parseInt(m.SK.split("#")[2])).toLocaleString()}, run Id{" "}
                      {m.SK.split("#")[2]}
                    </Typography>

                    <TableContainer>
                      <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        {transaction.Snapshot.STATUS === "SUCCESS" ? (
                          <TableHead>
                            <TableRow color="red">
                              <StyledTableCell>
                                <CheckCircleIcon color="success"></CheckCircleIcon>
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <Typography variant="h3" component="div">
                                  DMV
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <Typography variant="h3" component="div">
                                  RTS
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <Typography variant="h3" component="div">
                                  PS
                                </Typography>
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                        ) : (
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>
                                {" "}
                                <UnpublishedIcon color="error"></UnpublishedIcon>
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                        )}

                        {transaction.Snapshot.STATUS === "SUCCESS" ? (
                          <TableBody>
                            <StyledTableRow>
                              <StyledTableCell component="th" scope="row">
                                {" "}
                                TOTAL TAX
                              </StyledTableCell>
                              <StyledTableCell align="right">{m.Snapshot.TOTAL_TAX.DMV}</StyledTableCell>
                              <StyledTableCell align="right">
                                <AmountChip
                                  actualAmount={m.Snapshot.TOTAL_TAX.RTS}
                                  expectedAmount={m.Snapshot.TOTAL_TAX.DMV}
                                ></AmountChip>
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <AmountChip
                                  actualAmount={m.Snapshot.TOTAL_TAX.PS}
                                  expectedAmount={m.Snapshot.TOTAL_TAX.DMV}
                                ></AmountChip>
                              </StyledTableCell>
                            </StyledTableRow>

                            {expanded && (
                              <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                  {" "}
                                  TAX BREAKDOWN
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  <Stack>
                                    {m.Snapshot.TAX_BREAKDOWN.DMV.map(
                                      (f, i) =>
                                        f.amount && (
                                          <span key={i}>
                                            {f.description}: {f.amount || "0.00"}
                                          </span>
                                        )
                                    )}
                                  </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  <Stack>
                                    {m.Snapshot.TAX_BREAKDOWN.RTS.map(
                                      (f, i) =>
                                        f.amount && (
                                          <span key={i}>
                                            {f.description}: {f.amount || "0.00"}
                                          </span>
                                        )
                                    )}
                                  </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  <Stack>
                                    {m.Snapshot.TAX_BREAKDOWN.PS.map(
                                      (f, i) =>
                                        f.amount && (
                                          <span key={i}>
                                            {f.description}: {f.amount || "0.00"}
                                          </span>
                                        )
                                    )}
                                  </Stack>
                                </StyledTableCell>
                              </StyledTableRow>
                            )}

                            <StyledTableRow>
                              <StyledTableCell component="th" scope="row">
                                {" "}
                                TOTAL FEES
                              </StyledTableCell>
                              <StyledTableCell align="right">{m.Snapshot.TOTAL_FEES.DMV}</StyledTableCell>
                              <StyledTableCell align="right">
                                <AmountChip
                                  actualAmount={m.Snapshot.TOTAL_FEES.RTS}
                                  expectedAmount={m.Snapshot.TOTAL_FEES.DMV}
                                ></AmountChip>
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <AmountChip
                                  actualAmount={m.Snapshot.TOTAL_FEES.PS}
                                  expectedAmount={m.Snapshot.TOTAL_FEES.RTS}
                                ></AmountChip>
                              </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                              <StyledTableCell component="th" scope="row">
                                {" "}
                                FEES BREAKDOWN
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <Stack>
                                  {m.Snapshot.FEES_BREAKDOWN.DMV.map(
                                    (f, i) =>
                                      f.amount && (
                                        <span key={i}>
                                          {f.description}: {f.amount || "0.00"}
                                        </span>
                                      )
                                  )}
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <Stack>
                                  {m.Snapshot.FEES_BREAKDOWN.RTS.map(
                                    (f, i) =>
                                      f.amount && (
                                        <span key={i}>
                                          {f.description}: {f.amount || "0.00"}
                                        </span>
                                      )
                                  )}
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <Stack>
                                  {m.Snapshot.FEES_BREAKDOWN.PS.map(
                                    (f, i) =>
                                      f.amount && (
                                        <span key={i}>
                                          {f.description}: {f.amount || "0.00"}
                                        </span>
                                      )
                                  )}
                                </Stack>
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        ) : (
                          <TableBody>
                            <StyledTableRow>
                              <StyledTableCell>{m.Snapshot.ERRORS}</StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </>
                )}
              </CardContent>
              <CardActions>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleOpenModal("DMV Request", m.SourceRequest)}
                >
                  View DMV Log
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleOpenModal("RTS Request", m.RTSRequest)}
                >
                  View TNF Request
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleOpenModal("RTS Response", m.RTSResponse)}
                >
                  View TNF Response
                </Button>

                <Button variant="outlined" color="secondary" onClick={() => handleOpenModal("PS Request", m.PSRequest)}>
                  View PS Request
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleOpenModal("PS Response", m.PSResponse)}
                >
                  View PS Response
                </Button>
              </CardActions>
              <CardActions>
                <Typography
                  color="secondary"
                  variant="span"
                  component="span"
                  sx={{ fontSize: "0.5rem", textAlign: "center" }}
                >
                  Transaction id {transaction.TransactionID}
                </Typography>
              </CardActions>
            </Card>
          ))}
        </Box>
      )}
    </Box>
  );
}

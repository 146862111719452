import { Backdrop, Box, Button, CircularProgress, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";

import Bar from "../../components/Bar";

import API from "../../Api";
import { tokens } from "../../theme";
import { mockchartdata } from "../../data/mockData";
import Line from "../../components/Line";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const varianceButtons = ["RTS.FEES", "RTS.TAX", "PS.FEES", "PS.TAX", "CARL.FEES", "CARL.TAX"];

  const [barData, setBarData] = useState([]);
  const [varianceData, setVarianceData] = useState([]);
  const [varianceKeys, setVarianceKeys] = useState([]);
  const [varianceMap, setVarianceMap] = useState({});
  const [open, setOpen] = useState(false);
  const [showCarl, setShowCarl] = useState(true);
  const [barkeys, setBarkeys] = useState(["rtsTax", "rtsFees", "psTax", "psFees"]);

  const showProgress = () => {
    setOpen(true);
  };
  const hideProgress = () => {
    setOpen(false);
  };

  const varianceColors = [
    "rgb(255 9 9)",
    "rgb(255 76 9)",
    "rgb(255 145 9)",
    "rgb(255 219 9)",
    "rgb(7 180 7)",
    "rgb(255 219 9)",
    "rgb(255 145 9)",
    "rgb(255 76 9)",
    "rgb(255 9 9)",
  ];

  const dummyVarianceMap = {
    "$0": 0,
    "$0-$10": 0,
    "$10-$25": 0,
    "$25-$100": 0,
    "$100+": 0,
    "($0-$10)": 0,
    "($10-$25)": 0,
    "($25-$100)": 0,
    "($100+)": 0,
  };

  const accuraccyColors = ["rgb(11 163 210)", "rgb(8 188 174)", "rgb(184 49 194)", "rgb(147 13 157)"];

  useEffect(() => {
    setShowCarl(
      localStorage.getItem("app-config") ? JSON.parse(localStorage.getItem("app-config")).ShowCARL || false : false
    );

    const fetchBarChartData = async () => {
      showProgress();
      try {
        const sourceKey = "RTS";
        const r1 = await API.get(`/dashboard/${sourceKey}/latest`);
        console.log("dashboard data", r1.data);
        // const r = {
        //   data: mockchartdata,
        // };
        // console.log(r.data);
        let keys = r1.data.States; //Object.keys(r.data.bar);
        keys.sort();
        const chartData = [];
        const varianceMap = {};
        if (showCarl === true) {
          barkeys.push("carlTax", "carlFees");
        }

        for (let k of keys) {
          let cdata = {
            state: k,
            rtsTax:
              r1.data.Accuracy[k]["RTS"]["TAX"] < 0 ? -1 : roundToDecimal(r1.data.Accuracy[k]["RTS"]["TAX"] * 100, 2),
            rtsTaxColor: "hsl(257, 70%, 50%)",
            rtsFees:
              r1.data.Accuracy[k]["RTS"]["FEES"] < 0 ? -1 : roundToDecimal(r1.data.Accuracy[k]["RTS"]["FEES"] * 100, 2),
            rtsFeesColor: "hsl(171, 70%, 50%)",
            psTax:
              r1.data.Accuracy[k]["PS"]["TAX"] < 0 ? -1 : roundToDecimal(r1.data.Accuracy[k]["PS"]["TAX"] * 100, 2),
            psTaxColor: "hsl(174, 57%, 57%)",
            psFees:
              r1.data.Accuracy[k]["PS"]["FEES"] < 0 ? -1 : roundToDecimal(r1.data.Accuracy[k]["PS"]["FEES"] * 100, 2),
            psFeesColor: "hsl(220, 70%, 50%)",
          };

          if (showCarl === true) {
            console.log("X", k, r1.data.Accuracy[k].CARL);
            cdata.carlTax = r1.data.Accuracy[k].CARL
              ? r1.data.Accuracy[k].CARL.TAX < 0
                ? -1
                : roundToDecimal(r1.data.Accuracy[k].CARL.TAX * 100, 2)
              : 0;
            cdata.carlTaxColor = "hsl(199, 57%, 57%)";

            cdata.carlFees = r1.data.Accuracy[k].CARL
              ? r1.data.Accuracy[k].CARL.FEES < 0
                ? -1
                : roundToDecimal(r1.data.Accuracy[k].CARL.FEES * 100, 2)
              : 0;
            cdata.carlFeesColor = "hsl(199, 100%, 50%)";
          }

          chartData.push(cdata);

          //arrange variance chart data
          varianceMap["RTS.FEES"] = varianceMap["RTS.FEES"] || [];
          varianceMap["RTS.TAX"] = varianceMap["RTS.TAX"] || [];
          varianceMap["PS.FEES"] = varianceMap["PS.FEES"] || [];
          varianceMap["PS.TAX"] = varianceMap["PS.TAX"] || [];

          if (showCarl === true) {
            varianceMap["CARL.FEES"] = varianceMap["CARL.FEES"] || [];
            varianceMap["CARL.TAX"] = varianceMap["CARL.TAX"] || [];
          }

          const varianceData = [];

          varianceMap["RTS.FEES"].push({ state: k, label: "RTS.FEES", ...r1.data.Variance[k].PCT.RTS.FEES });
          varianceMap["RTS.TAX"].push({ state: k, label: "RTS.TAX", ...r1.data.Variance[k].PCT.RTS.TAX });
          varianceMap["PS.FEES"].push({ state: k, label: "PS.FEES", ...r1.data.Variance[k].PCT.PS.FEES });
          varianceMap["PS.TAX"].push({ state: k, label: "PS.TAX", ...r1.data.Variance[k].PCT.PS.TAX });

          //set carleton data
          if (showCarl) {
            if (r1.data.Variance[k].PCT.CARL) {
              varianceMap["CARL.FEES"].push({ state: k, label: "CARL.FEES", ...r1.data.Variance[k].PCT.CARL.FEES });
              varianceMap["CARL.TAX"].push({ state: k, label: "CARL.TAX", ...r1.data.Variance[k].PCT.CARL.TAX });
            } else {
              varianceMap["CARL.FEES"].push({ state: k, label: "CARL.FEES", ...dummyVarianceMap });
              varianceMap["CARL.TAX"].push({ state: k, label: "CARL.TAX", ...dummyVarianceMap });
            }
          }
        }
        console.log(chartData);
        console.log("variance map", varianceMap);
        setBarData(chartData);
        setVarianceData(varianceMap["RTS.FEES"]);
        setVarianceMap(varianceMap);
        setVarianceKeys(r1.data.VarianceRange.map((m) => m.label));
      } catch (error) {
      } finally {
        hideProgress();
      }
    };

    fetchBarChartData();
  }, [showCarl]);

  const roundToDecimal = (value, precision) => {
    return Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);
  };

  return (
    <Box m="10px">
      <Typography variant="h3" align="center" color={colors.grey[200]} scope="row">
        Taxes and Fees Accuracy Graph
      </Typography>

      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} onClick={hideProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Bar
        data={barData}
        groupMode={"grouped"}
        layout={"vertical"}
        indexBy={"state"}
        keys={barkeys}
        colorScheme="accent"
        legends={{ left: "Accuracy", buttom: "" }}
        colorList={accuraccyColors}
        leftAxisFormat={(d) => `${d}%`}
      />

      <Typography variant="h3" align="center" color={colors.grey[200]} scope="row">
        Variance Accuracy Distributions By State
      </Typography>
      <Stack direction={"row"} sx={{ ml: "50px", mt: "10px" }}>
        {varianceButtons.map((m, i) => (
          <Button
            key={i}
            variant="outlined"
            color="success"
            sx={{ mr: "5px" }}
            onClick={() => {
              console.log("clicked", m, varianceMap[m]);
              setVarianceData(varianceMap[m]);
            }}
          >
            {m}
          </Button>
        ))}
      </Stack>
      {varianceData ? (
        <Bar
          data={varianceData}
          groupMode={"stacked"}
          layout={"horizontal"}
          indexBy={"state"}
          keys={varianceKeys}
          colorList={varianceColors}
          legends={{ left: "", buttom: "" }}
        />
      ) : null}
    </Box>
  );
};

export default Dashboard;

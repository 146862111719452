import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import { Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import EventEmitter from "eventemitter3";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LaunchIcon from "@mui/icons-material/Launch";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

import SearchIcon from "@mui/icons-material/Search";
import { useOktaAuth } from "@okta/okta-react";
import { useNavigate } from "react-router-dom";

import { Autocomplete } from "./Autocomplete";
import "@algolia/autocomplete-theme-classic";
import { getAlgoliaResults } from "@algolia/autocomplete-js";
import algoliasearch from "algoliasearch";
import { ProductItem } from "./ProductItem";
import API from "../../Api";

const Topbar = () => {
  const appId = "QZAH6JE60G";
  const apiKey = "6b5a99d1e1e78bf22618c3a5ec278d96";
  const searchClient = algoliasearch(appId, apiKey);

  const sources = ["RTS", "PS"];
  const eventEmitter = new EventEmitter();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { oktaAuth, authState } = useOktaAuth();

  const handleLogout = async () => {
    localStorage.removeItem("okta-token");
    localStorage.removeItem("user-info");
    localStorage.removeItem("app-config");
    oktaAuth.signOut();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  eventEmitter.on("change-source", (data) => {
    console.log("change-source", data);
  });
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    eventEmitter.emit("change-source", sources[index]);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box display="flex" sx={{ minWidth: "400px" }} backgroundColor={colors.primary[400]} borderRadius="3px">
        {/* <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" id="autocomplete" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton> */}
        {/* algolia backend search  */}
        {/* <Autocomplete
          openOnFocus={true}
          getSources={({ query }) => {
            console.log("query=" + query);
            return [
              {
                sourceId: "documents",
                getItems() {
                  return getAlgoliaResults({
                    searchClient,
                    queries: [
                      {
                        indexName: "analytic_tool",
                        query,
                      },
                    ],
                  });
                },
                templates: {
                  item({ item, components }) {
                    console.log(item);
                    return <ProductItem hit={item} components={components} />;
                  },
                },
              },
            ];
          }}
        /> */}

        <Autocomplete
          openOnFocus={true}
          getSources={async ({ query }) => {
            //console.log("query=" + query);
            let data = [];
            if (query && query.length > 3) {
              const r = await API.get(`/lunrsearch/dealer_vehicle?query=${query}`);

              data = r.data.map((m) => {
                console.log(m);
                const splits = m.ref.split("#");
                return {
                  ref: m.ref,
                  runtime: splits[2],
                  state: splits[4],
                  tnxId: splits[6],
                  doc: m.doc,
                };
              });
            }

            return [
              {
                sourceId: "links",
                getItems() {
                  return [data];
                },
                templates: {
                  item({ item, components }) {
                    return <ProductItem hit={item} components={components} colors={colors} />;
                  },
                },
                // templates: {
                //   header() {
                //     return "Suggestions";
                //   },
                //   item({ item }) {
                //     return `Result: ${item.label}`;
                //   },
                //   footer() {
                //     return "Footer";
                //   },
                // },
                // ...
              },
            ];
          }}
        />
      </Box>

      {/* {
            sourceId: "products",
            getItems() {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: "instant_search",
                    query,
                  },
                ],
              });
            },
            templates: {
              item({ item, components }) {
                return <ProductItem hit={item} components={components} />;
              },
            },
          }, */}
      {/* ICONS */}
      <Box display="flex">
        <IconButton target="_blank" href="https://pages.ghe.coxautoinc.com/RTS-RegUSA/mact/">
          <LaunchIcon />
        </IconButton>

        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
        </IconButton>

        <IconButton onClick={handleLogout}>
          <PowerSettingsNewIcon />
        </IconButton>

        <div>
          <List color="secondary" sx={{ backgroundColor: colors.primary[900], pb: 0, pt: 0 }}>
            <ListItemButton onClick={handleClickListItem}>
              <ListItemText primary={sources[selectedIndex]} />
            </ListItemButton>
          </List>
          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "lock-button",
              "role": "listbox",
            }}
          >
            {sources.map((option, index) => (
              <MenuItem
                key={option}
                selected={index === selectedIndex}
                disabled={index !== 0}
                onClick={(event) => handleMenuItemClick(event, index)}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </div>

        {/* <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton>
          <PersonOutlinedIcon />
        </IconButton> */}
      </Box>
    </Box>
  );
};

export default Topbar;

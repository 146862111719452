import { useEffect, useState } from "react";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, Icon } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
//import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import ScheduleIcon from "@mui/icons-material/Schedule";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DataObjectIcon from "@mui/icons-material/DataObject";
import SettingsIcon from "@mui/icons-material/Settings";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { useOktaAuth } from "@okta/okta-react";
import DataObject from "@mui/icons-material/DataObject";

const Item = ({ title, to, icon, selected, setSelected, mb = 0 }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
        marginBottom: mb,
      }}
      onClick={() => setSelected(title)}
      icon={icon}
      component={<Link to={to} />}
    >
      <Typography>{title}</Typography>
      {/* <Link to={to} /> */}
    </MenuItem>
  );
};

const AppSidebar = () => {
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const [userInfo, setUserInfo] = useState({});
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    console.log("appside bar", location.pathname);

    if (location.pathname.startsWith("/secured/")) {
      const word = location.pathname.replace("/secured/", "");
      const firstLetter = word.charAt(0);

      const firstLetterCap = firstLetter.toUpperCase();

      const remainingLetters = word.slice(1);

      const capitalizedWord = firstLetterCap + remainingLetters;
      console.log("capitalizedWord", capitalizedWord);
      setSelected(capitalizedWord);
    }
    // if (location.pathname === "/secured/transactions") {
    //   setSelected("Transactions");
    // }
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo({});
    } else {
      console.log("fetch okta user info");
      const info = localStorage.getItem("user-info") ? JSON.parse(localStorage.getItem("user-info")) : {};
      setUserInfo(info);
    }
  }, [authState, oktaAuth]);

  return (
    <Box
      sx={{
        "& .ps-sidebar-container": {
          backgroundColor: `${colors.primary[400]} !important`,
        },
        "& .ps-menu-button:hover": {
          ...(theme.palette.mode === "dark"
            ? {
                backgroundColor: `${colors.primary[500]} !important`,
              }
            : {
                backgroundColor: `${colors.primary[900]} !important`,
              }),
        },
        "& .ps-menu-button.ps-active": {
          ...(theme.palette.mode === "dark"
            ? {
                backgroundColor: `${colors.primary[500]} !important`,
              }
            : {
                backgroundColor: `${colors.primary[900]} !important`,
              }),
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
      }}
    >
      <Sidebar
        collapsed={isCollapsed}
        style={{ display: "flex", height: "100vh", borderColor: "transparent", minHeight: "100vh" }}
      >
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                <Typography variant="h3" color={colors.grey[100]}>
                  MACT
                </Typography>

                {/* <Typography variant="h6" color={colors.grey[100]}>
                  Accuracy Comparison Tool
                </Typography> */}
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`../../assets/_nerd.webp`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography variant="h3" color={colors.grey[100]} fontWeight="bold" sx={{ m: "10px 0 0 0" }}>
                  {userInfo.UserName}
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  {userInfo.UserRole ? userInfo.UserRole : "Member"}
                </Typography>
                <Typography variant="span" color={colors.grey[100]} sx={{ fontSize: "0.5rem" }}>
                  Last login {new Date(userInfo.Timestamp).toLocaleString()}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "0%"}>
            <Item
              title="Dashboard"
              to="dashboard"
              icon={<DashboardIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item title="Runs" to="runs" icon={<ScheduleIcon />} selected={selected} setSelected={setSelected} />

            <Item
              title="Transactions"
              to="transactions"
              icon={<ReceiptLongIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              mb={20}
              title="Events"
              to="events"
              icon={<EventNoteIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Users"
              to="users"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Mappings"
              to="mappings"
              icon={<DeviceHubIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item title="Configs" to="configs" icon={<SettingsIcon />} selected={selected} setSelected={setSelected} />

            <Item
              title="Augmentation"
              to="augmentation"
              icon={<DataObject />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0 5px 20px" }}>
              Build # {window.REACT_APP_VERSION}
            </Typography>
            <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0 5px 20px", textAlign: "left" }}>
              Model Accuracy Comparison Tool
            </Typography>
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default AppSidebar;

import { Chip, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

const AmountChip = ({ actualAmount, expectedAmount }) => {
  console.log(actualAmount, expectedAmount);
  return (
    <Stack justifyContent="center" alignItems="center" spacing={2}>
      <Chip
        sx={{ fontSize: "2em" }}
        label={"$" + Number(actualAmount).toFixed(2)}
        color={Number(actualAmount) === Number(expectedAmount) ? "success" : "error"}
      />
      <Typography variant="h5" align="center">
        (${Number(expectedAmount - actualAmount).toFixed(2)})
      </Typography>
    </Stack>
  );
};

export default AmountChip;

import React from "react";
import PropTypes from "prop-types";
import { Box, Stack, Typography } from "@mui/material";

const RegDetails = ({ metadata }) => {
  console.log(metadata);

  return metadata ? (
    <Box sx={{ border: "1px gray solid", borderRadius: "2px", padding: "5px", color: "gray" }}>
      <Stack direction="row" spacing={2}>
        <Typography variant="h6" align="center">
          City:{metadata.RegistrationCityName ? metadata.RegistrationCityName.RTS : "NA"}, County:
          {metadata.RegistrationCountyName ? metadata.RegistrationCountyName.RTS : "NA"}, State:
          {metadata.State ? metadata.State.RTS : "NA"}, {metadata.ZipCode.RTS} |{" "}
          {metadata.Vehicle ? metadata.Vehicle.Year + "-" + metadata.Vehicle.Make + "-" + metadata.Vehicle.Model : ""} |{" "}
          {metadata.FuelType ? metadata.FuelType.RTS : ""},{metadata.Grossweight ? metadata.Grossweight.RTS : ""}
        </Typography>
      </Stack>
    </Box>
  ) : null;
};

RegDetails.propTypes = {};

export default RegDetails;

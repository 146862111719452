import { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";

import Login from "./scenes/login/Login";
import Dashboard from "./scenes/dashboard/Dashboard";
import config from "./config";
import OktaAuth, { toRelativeUrl } from "@okta/okta-auth-js";
import { Security, LoginCallback } from "@okta/okta-react";

import SecuredShell from "./scenes/shell/SecuredShell";
import Mapping from "./scenes/mapping/Mapping";
import Events from "./scenes/events/Events";
import Runs from "./scenes/runs/Runs";
import Transactions from "./scenes/transactions/Transactions";
import Users from "./scenes/users/Users";
import Configs from "./scenes/configs/Configs";
import Augmentation from "./scenes/augmentation/Augmentation";

const oktaAuth = new OktaAuth(config.oidc);

function App() {
  const navigate = useNavigate();

  const location = useLocation();

  const [theme, colorMode] = useMode();

  useEffect(() => {
    console.log("called useEffect in App.jsx", process.env.NODE_ENV);
    console.log(location.pathname);
    return () => {};
  }, []);

  const customAuthHandler = () => {
    console.log("inside customAuthHandler");
    navigate("/login");
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    console.log("restoreOriginalUri", originalUri);
    navigate(toRelativeUrl(originalUri || "", window.location.origin));
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        {/* <div className="app"> */}
        <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
          {/* <AppSidebar isSidebar={isSidebar} /> */}
          {/* <main className="content"> */}
          {/* <Topbar setIsSidebar={setIsSidebar} /> */}
          <Routes>
            <Route index element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="secured" element={<SecuredShell />}>
              <Route path="" element={<Dashboard />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="runs" element={<Runs />} />
              <Route path="transactions" element={<Transactions />} />
              <Route path="mappings" element={<Mapping />} />
              <Route path="events" element={<Events />} />
              <Route path="users" element={<Users />} />
              <Route path="configs" element={<Configs />} />
              <Route path="augmentation" element={<Augmentation />} />
            </Route>

            <Route path="/login/callback" element={LoginCallback} />
          </Routes>
          {/* </main> */}
        </Security>
        {/* </div> */}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;

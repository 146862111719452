import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FolderIcon from "@mui/icons-material/Folder";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { Buffer } from "buffer";

import API from "../../Api";
import { tokens } from "../../theme";
import { states } from "../../data/state-codes";
import generateByState from "../../data/augmenter";
import CopyToClipboard from "react-copy-to-clipboard";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const MAX_IMAGE_SIZE = 1024 * 50; //100 KB

function Augmentation() {
  const sourceKey = "RTS";
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [openAlert, setOpenAlert] = useState(false);
  const [filedata, setFiledata] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState([]);
  const [alertContent, setAlertContent] = useState(null);
  const [severity, setSeverity] = useState("error");
  const [state, setState] = useState("AL");

  const [openModal, setOpenModal] = useState(false);

  const showProgress = () => {
    setOpen(true);
  };
  const hideProgress = () => {
    setOpen(false);
  };

  useEffect(() => {}, []);

  const selectFile = async ($event) => {
    console.log("upload file", $event);
    setMessage(null);
    setSeverity("error");
    try {
      let reader = new FileReader();

      reader.onload = async (e) => {
        //console.log("length: ", e.target.result.includes("data:image/jpeg"));
        console.log("data: ", e.target.result.length);
        if (!e.target.result.includes("data:application/json")) {
          setOpenAlert(true);
          setAlertContent("Wrong file type - JSON only.");
          setTimeout(() => {
            setOpenAlert(false);
            setAlertContent(null);
          }, 10000);
          return;
        }
        if (e.target.result.length > MAX_IMAGE_SIZE) {
          setOpenAlert(true);
          setAlertContent("File size is too long.");
          setTimeout(() => {
            setOpenAlert(false);
            setAlertContent(null);
          }, 10000);
          return;
        }

        let data = Buffer.from(e.target.result.split(",")[1], "base64");

        try {
          let jsonData = JSON.parse(data);
          let tempMessage = "";
          tempMessage = `State=${jsonData.state} \t Source=${jsonData.source}\n`;
          tempMessage += `There are ${jsonData.transactions.length} transactions in file.\t\n`;
          if (jsonData.synthetic === true) {
            tempMessage += `Synthetic flag is turned on indicating data is not from real DVM.\n`;
          } else {
            tempMessage += `Synthetic flag is turned off indicating data is from real DVM.\n`;
          }

          if (jsonData.source_to_compare) {
            tempMessage += `The source of truth for this file is ${jsonData.source_to_compare}.\n`;
          } else {
            tempMessage += `The source of truth for this file is DMV.\n`;
          }
          tempMessage += `Click on UPLOAD button to process this file.`;
          setMessage(tempMessage);
          setFiledata(data);
        } catch (error) {
          console.log(error);
        }
        // console.log("data: ", data);
      };
      reader.readAsDataURL($event.target.files[0]);
    } catch (error) {
    } finally {
      hideProgress();
    }
  };

  const uploadFile = async () => {
    console.log("upload file");
    setSeverity("error");
    try {
      showProgress();
      console.log("filedata", filedata);
      const r = await API.get(`/get-signed-upload-url/${sourceKey}`);
      console.log(r.data.uploadURL);

      const result = await fetch(r.data.uploadURL, {
        method: "PUT",
        body: filedata,
        headers: { "Content-Type": "application/json" },
      });
      console.log("uploaded");
      setOpenAlert(true);
      setSeverity("success");
      setAlertContent("File was uploaded successfully. Please check the events.");
      setTimeout(() => {
        setOpenAlert(false);
      }, 10000);
    } catch (error) {
      setOpenAlert(true);
      setAlertContent("File was upload failed.");
      setTimeout(() => {
        setOpenAlert(false);
      }, 10000);
    } finally {
      hideProgress();
    }
  };

  const generateFile = async () => {
    console.log("generate file for state ", state);
    let jsonObject = generateByState(state);
    console.log(jsonObject);
    setmodalContent(jsonObject);
    await handleOpenModal();
  };

  const handleOpenModal = async () => {
    try {
      showProgress();
      setOpenModal(true);
      setCopied(false);
    } catch (error) {
    } finally {
      hideProgress();
    }
  };
  const handleCloseModal = () => setOpenModal(false);

  const [modalContent, setmodalContent] = useState(null);
  const [copied, setCopied] = useState(false);

  const downloadJSON = async () => {
    console.log("download json");
    try {
      showProgress();
      let blob = new Blob([JSON.stringify(modalContent)], { type: "text/json" });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = `${state}.${new Date().getTime()}.json`;
      a.click();
    } catch (error) {
    } finally {
      hideProgress();
    }
  };

  const uploadGenerateFile = async () => {
    // let data = Buffer.from(JSON.stringify(modalContent), "base64");
    let data = JSON.stringify(modalContent);
    console.log("upload file", data);
    handleCloseModal();

    try {
      showProgress();

      const r = await API.get(`/get-signed-upload-url/${sourceKey}`);
      console.log(r.data.uploadURL);

      const result = await fetch(r.data.uploadURL, {
        method: "PUT",
        body: data,
        headers: { "Content-Type": "application/json" },
      });
      console.log("uploaded");
      setOpenAlert(true);
      setSeverity("success");
      setAlertContent("File was uploaded successfully. Please check the events.");
      setTimeout(() => {
        setOpenAlert(false);
      }, 10000);
    } catch (error) {
      setOpenAlert(true);
      setAlertContent("File was upload failed.");
      setTimeout(() => {
        setOpenAlert(false);
      }, 10000);
    } finally {
      hideProgress();
    }
  };

  return (
    <Box m="10px">
      <Typography variant="h3" align="center" color={colors.grey[200]} scope="row"></Typography>

      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} onClick={hideProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Card
        sx={{
          marginTop: 5,
          marginLeft: 10,
          marginRight: 10,
          borderRadius: 2,
          padding: 5,
          backgroundColor: `${colors.primary[400]} !important`,
        }}
      >
        <CardContent sx={{ p: 0 }}>
          <Box>
            <Stack direction={"column"} justifyContent="space-evenly" alignItems="flex-start" spacing={2}>
              <Typography variant="h5" color="text.secondary">
                You can only upload a JSON file. Max file size is {MAX_IMAGE_SIZE / 1024} KB.
              </Typography>
              <Button component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<FolderIcon />}>
                CHOOSE A FILE
                <VisuallyHiddenInput type="file" accept=".json,application/json" onChange={selectFile} />
              </Button>

              <Typography variant="span" color="text.secondary">
                {message}
              </Typography>

              <Button
                component="label"
                color="secondary"
                variant="contained"
                tabIndex={1}
                startIcon={<CloudUploadIcon />}
                disabled={filedata === null}
                onClick={uploadFile}
              >
                UPLOAD
              </Button>
            </Stack>
          </Box>
        </CardContent>
      </Card>

      <Card
        sx={{
          marginTop: 5,
          marginLeft: 10,
          marginRight: 10,
          borderRadius: 2,
          padding: 5,
          backgroundColor: `${colors.primary[400]} !important`,
        }}
      >
        <CardContent sx={{ p: 0 }}>
          <Box>
            <Stack direction={"column"} justifyContent="space-evenly" alignItems="flex-start" spacing={2}>
              <Typography variant="h5" color="text.secondary">
                Help System generate a random input file for the selected state. Select the state from dropdown below
                and hit on generate button. Download the file for a quick sanity check and upload to the system.
              </Typography>
              <FormControl sx={{ m: 1, minWidth: 200 }}>
                <InputLabel id="demo-simple-select-helper-label">State</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={state}
                  label="State"
                  onChange={async (e) => {
                    setState(e.target.value);
                  }}
                >
                  {states.map((m) => (
                    <MenuItem key={m.code} value={m.code}>
                      {m.label}
                    </MenuItem>
                  ))}
                </Select>
                {/* <FormHelperText>Select the state</FormHelperText> */}
              </FormControl>

              <Typography variant="span" color="text.secondary">
                {message}
              </Typography>

              <Button
                component="label"
                color="secondary"
                variant="contained"
                tabIndex={1}
                startIcon={<AutoFixHighIcon />}
                disabled={state === null}
                onClick={generateFile}
              >
                Generate
              </Button>
            </Stack>
          </Box>
        </CardContent>
      </Card>
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={() => setOpenAlert(false)}>
        <Alert onClose={() => setOpenAlert(false)} severity={severity} sx={{ width: "300px" }}>
          {alertContent}
        </Alert>
      </Snackbar>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "600px",

            maxHeight: "80vh",
            bgcolor: "background.paper",
            border: "2px solid #000",
            overflowY: "scroll",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Button variant="outlined" color="error" onClick={handleCloseModal}>
            Close
          </Button>
          <CopyToClipboard
            sx={{ ml: "5px" }}
            text={JSON.stringify(modalContent, null, 2)}
            onCopy={() => setCopied(true)}
          >
            <Button variant="outlined" color="success">
              Copy
            </Button>
          </CopyToClipboard>
          {copied ? (
            <Typography variant="span" color={"green"} component="span" sx={{ ml: "5px" }}>
              Copied.
            </Typography>
          ) : null}
          {/* <Button variant="outlined" color="success" onClick={handleCloseModal}>
            Copy
          </Button> */}
          <pre>{JSON.stringify(modalContent, null, 2)}</pre>
          <Stack direction={"row"} spacing={2}>
            <Button variant="outlined" color="error" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="outlined" color="secondary" onClick={downloadJSON}>
              Download
            </Button>
            <Button startIcon={<CloudUploadIcon />} color="secondary" variant="contained" onClick={uploadGenerateFile}>
              Upload
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
}

export default Augmentation;

import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import API from "../../Api";
import { tokens } from "../../theme";

function Events() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [events, setevents] = useState([]);
  const [open, setOpen] = React.useState(false);

  const showProgress = () => {
    setOpen(true);
  };
  const hideProgress = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchEventsBySource("RTS");
  }, []);

  const fetchEventsBySource = async (sourceKey) => {
    try {
      showProgress();
      const r = await API.get(`/events/${sourceKey}`);
      console.log(r.data);

      setevents(r.data);
    } catch (error) {
    } finally {
      hideProgress();
    }
  };

  const getAdditionalInfo = (info) => {
    // let mesages = JSON.parse(info)
    //   .map((m) => m.message.replaceAll("'", ""))
    //   .join(", ");

    // return mesages;
    return info;
  };
  const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.mode === "dark" ? colors.grey[900] : colors.grey[900],
        color: colors.grey[100],
        maxWidth: 500,
        fontSize: "0.9rem",
        border: "1px solid",
      },
    })
  );

  const replaceIcons = (note) => {
    if (note) {
      note = note
        .replace(":error:", "❌ ")
        .replace(":informations:", "ℹ️ ")
        .replace(":warning:", "⚠️ ")
        .replace(":rocket: ", "🚀 ");
    }
    return note;
  };

  return (
    <Box m="10px">
      <Typography variant="h3" align="center" color={colors.grey[200]} scope="row">
        All Events ({events.length})
      </Typography>

      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} onClick={hideProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ fontSize: "1.0rem" }}>
                Run ID
              </TableCell>
              <TableCell align="left" sx={{ fontSize: "1.0rem" }}>
                Source
              </TableCell>
              <TableCell align="left" sx={{ fontSize: "1.0rem" }}>
                Note
              </TableCell>
              <TableCell align="left" sx={{ fontSize: "1.0rem" }}>
                Timestamp
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((row) => (
              <TableRow key={row.GSI1SK} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell align="left">{row.GSI1SK.replace("EVENT#TS#", "")}</TableCell>
                <TableCell align="left">{row.PK.replace("SRC#", "")}</TableCell>
                <TableCell align="left">
                  {replaceIcons(row.Note)}
                  {row.AdditionalNote && (
                    <HtmlTooltip title={getAdditionalInfo(row.AdditionalNote)}>
                      <IconButton aria-label="delete">
                        <InfoIcon color="success" />
                      </IconButton>
                    </HtmlTooltip>
                  )}
                </TableCell>
                <TableCell align="left">{new Date(row.Timestamp).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default Events;

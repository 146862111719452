export default {
  oidc: {
    issuer: "https://coxauto.okta.com",
    clientId: "0oa1sz7vfoaCaarDH0h8",
    scopes: ["openid", "profile", "email"],
    redirectUri: `${window.location.origin}/login/callback`,
    disableHttpsCheck: false,
    useInteractionCodeFlow: false,
    useClassicEngine: true,
  },

  //prod
  // issuer: "https://coxauto.okta.com",
  // clientId: "0oa1sz7vfoaCaarDH0h8",

  //dev
  // issuer: "https://dev-81427545.okta.com/oauth2/default",
  // clientId: "0oa8xbrtxi7HfKkFm5d7",

  // issuer: "https://atg.oktapreview.com",
  // clientId: "0oa1rm90kk3AWMEE20h8",

  // scopes: ["openid", "profile", "email"],
  // redirectUri: `${window.location.origin}/login/callback`,
  // widget: {
  //   issuer: "https://dev-81427545.okta.com/oauth2/default",
  //   clientId: "0oa8xbrtxi7HfKkFm5d7",
  //   redirectUri: `${window.location.origin}/login/callback`,
  //   scopes: ["openid", "profile", "email"],
  //   useInteractionCode: true,
  // },
};

const vehicles = [
  {
    VIN: "4T1R11AKXPU136015",
    Body: "4DR",
    FuelType: "G",
    VehType: "P",
    ModelYear: 2023,
    WeightGross: 4700,
    WeightUnladen: 3400,
    FairMarketValue: 105000,
    YearFirstSold: 2022,
  },
  {
    VIN: "5YJSA1S12EFP29403",
    Body: "4DRHT",
    FuelType: "E",
    VehType: "P",
    ModelYear: 2014,
    WeightGross: 5900,
    WeightUnladen: 4647,
    FairMarketValue: 105000,
    YearFirstSold: 2014,
  },
  {
    VIN: "5YJXCBE20GF005643",
    Body: "UTILITY",
    FuelType: "E",
    VehType: "T",
    ModelYear: 2016,
    WeightGross: 6768,
    WeightUnladen: 5267,
    FairMarketValue: 105000,
    YearFirstSold: 2017,
  },
  {
    VIN: "1FTEW1EP2GKE40980",
    Body: "TRUCK",
    FuelType: "G",
    VehType: "T",
    ModelYear: 2016,
    WeightGross: 6350,
    WeightUnladen: 4709,
    FairMarketValue: 105000,
    YearFirstSold: 2017,
  },
  {
    VIN: "KM8NUDCC4CU180616",
    Body: "UTILITY",
    FuelType: "G",
    VehType: "T",
    ModelYear: 2012,
    WeightGross: 5908,
    WeightUnladen: 4431,
    FairMarketValue: 105000,
    YearFirstSold: 2015,
  },
  {
    VIN: "KMHGC4DD5CUJ78666",
    Body: "4DRHT",
    FuelType: "G",
    VehType: "P",
    ModelYear: 2012,
    WeightGross: 5100,
    WeightUnladen: 3971,
    FairMarketValue: 105000,
    YearFirstSold: 2011,
  },
  {
    VIN: "2HGFG21587H950402",
    Body: "COUPE",
    FuelType: "G",
    VehType: "P",
    ModelYear: 2007,
    WeightGross: 4000,
    WeightUnladen: 2886,
    FairMarketValue: 105000,
    YearFirstSold: 2007,
  },
  {
    VIN: "WAUAFAFL0CA061659",
    Body: "4DRHT",
    FuelType: "G",
    VehType: "P",
    ModelYear: 2012,
    WeightGross: 4700,
    WeightUnladen: 3505,
    FairMarketValue: 105000,
    YearFirstSold: 2013,
  },
  {
    VIN: "1GT125CG2BF256225",
    Body: "TRUCK",
    FuelType: "G",
    VehType: "T",
    ModelYear: 2011,
    WeightGross: 9500,
    WeightUnladen: 6562,
    FairMarketValue: 105000,
    YearFirstSold: 2010,
  },
  {
    VIN: "WDDHF8HB3BA508701",
    Body: "4DRHT",
    FuelType: "G",
    VehType: "P",
    ModelYear: 2011,
    WeightGross: 5100,
    WeightUnladen: 3979,
    FairMarketValue: 105000,
    YearFirstSold: 2011,
  },
];

const ZIPS = {
  AK: { zip: "99501", city: "Anchorage", county: "Anchorage" },
  AL: { zip: "35006", city: "Adger", county: "Jefferson" },
  AR: { zip: "71613", city: "Pine Bluff", county: "Jefferson" },
  AZ: { zip: "85001", city: "Phoenix", county: "Maricopa" },
  CA: { zip: "92123", city: "San Diego", county: "San Diego" },
  CO: { zip: "80482", city: "Winter Park", county: "Grand" },
  CT: { zip: "06335", city: "Gales Ferry", county: "New London" },
  DC: { zip: "20001", city: "Washington", county: "District of Columbia" },
  DE: { zip: "19702", city: "Newark", county: "New Castle" },
  FL: { zip: "32216", city: "Jacksonville", county: "Duval" },
  GA: { zip: "31548", city: "Kingsland", county: "Camden" },
  HI: { zip: "96701", city: "Aiea", county: "Honolulu" },
  IA: { zip: "50001", city: "Ackworth", county: "Warren" },
  ID: { zip: "83210", city: "Aberdeen", county: "Bingham" },
  IL: { zip: "60646", city: "Chicago", county: "Cook" },
  IN: { zip: "46001", city: "Alexandria", county: "Madison" },
  KS: { zip: "66104", city: "Kansas City", county: "Wyandotte" },
  KY: { zip: "42437", city: "Morganfield", county: "Union" },
  LA: { zip: "70001", city: "Metairie", county: "Jefferson" },
  MA: { zip: "01001", city: "Agawam", county: "Hampden" },
  MD: { zip: "21201", city: "Baltimore", county: "Baltimore City" },
  ME: { zip: "04607", city: "Gouldsboro", county: "Hancock" },
  MI: { zip: "48050", city: "New Haven", county: "Macomb" },
  MN: { zip: "55123", city: "Saint Paul", county: "Dakota" },
  MO: { zip: "63005", city: "Chesterfield", county: "Saint Louis" },
  MS: { zip: "39209", city: "Jackson", county: "Hinds" },
  MT: { zip: "59001", city: "Absarokee", county: "Stillwater" },
  NC: { zip: "27545", city: "Knightdale", county: "Wake" },
  ND: { zip: "58001", city: "Abercrombie", county: "Richland" },
  NE: { zip: "68001", city: "Abie", county: "Butler" },
  NH: { zip: "03032", city: "Auburn", county: "Rockingham" },
  NJ: { zip: "07001", city: "Avenel", county: "Middlesex" },
  NM: { zip: "87001", city: "Algodones", county: "Sandoval" },
  NV: { zip: "89002", city: "Henderson", county: "Clark" },
  NY: { zip: "10001", city: "Manhattan", county: "New York" },
  OH: { zip: "43001", city: "Alexandria", county: "Licking" },
  OK: { zip: "73001", city: "Albert", county: "Caddo" },
  OR: { zip: "97042", city: "Mulino", county: "Clackamas" },
  PA: { zip: "16001", city: "Butler", county: "Butler" },
  RI: { zip: "02801", city: "Adamsville", county: "Newport" },
  SC: { zip: "29445", city: "Goose Creek", county: "Berkeley" },
  SD: { zip: "57103", city: "Sioux Falls", county: "Minnehaha" },
  TN: { zip: "37010", city: "Adams", county: "Robertson" },
  TX: { zip: "79835", city: "El Paso", county: "El Paso" },
  UT: { zip: "84101", city: "Salt Lake City", county: "Salt Lake" },
  VA: { zip: "20101", city: "Dulles", county: "Loudoun" },
  VT: { zip: "05001", city: "White River Junction", county: "Windsor" },
  WA: { zip: "98002", city: "Auburn", county: "King" },
  WI: { zip: "53001", city: "Adell", county: "Sheboygan" },
  WV: { zip: "24712", city: "Athens", county: "Mercer" },
  WY: { zip: "82001", city: "Cheyenne", county: "Laramie" },
};
const TEMPLATE_TRANSCTION = {};
const TEMPLATE_JSON = {
  state: "TX",
  timestamp: "1692734079898",
  source: "RTS",
  source_to_compare: "PS",
  synthetic: true,
  augmented: false,

  transactions: [],
};

const generateByState = (state) => {
  console.log("executing the program");
  //accept data from terminal and put in a variable name state

  console.log(state);
  console.log(`No of vehicles ${vehicles.length}`);
  const location = ZIPS[state];
  console.log(location);
  //prepare the json by iterating vehicles
  //deep copy TEMPLATE_JSON to file data variable

  const filedata = {
    state: state,
    timestamp: Date.now(),
    source: "RTS",
    source_to_compare: "PS",
    synthetic: true,
    augmented: false,
    transactions: [],
  };

  let ts = new Date().getTime();
  let counter = 1;
  vehicles.map((vehicle) => {
    const transaction = {
      transaction_id: "1",
      transaction_date: "12/9/2023",
      status: "Finalized",
      product_id: 500,
      transaction_type: "New Registration",
      number_liens: 1,
      client: {
        client_id: "1141431",
        client_name: "NEXTGEAR MOTORS INC",
        client_type: "DEALER",
      },
      vehicle: {
        vin: "1GYS4GKL5PR503438",
        plate_number: "KKP8024",
        new_or_used: "USED",
        model_year: 2017,
        bodystyle: "4 Door",
        vehicle_class_code: "Less than 16,000 lbs.",
        lease_status: "NON-LEASE",
        gross_sales_price: 100000,
        weight_gross: 6100,
        weight_unladen: 0,
        trade_in: 20000,
        fair_market_value: 0,
        registration_period: "",
        year_first_sold: 0,
      },
      party_owner: {
        party_role: "Owner",
        party_type: "Individual",
        party_last_name: "Filler",
        party_dob: "7/25/1996",
      },
      party_registrant: {
        party_role: "",
        party_type: "",
        party_city: "",
        party_state: "",
        party_zip: "",
        party_county: "",
        party_last_name: "",
        party_dob: "",
      },
      dealer_fees: {
        processing_fee: 85,
      },
      aftermarket: {
        esa_fee: 3000,
        accessorie_fee: 1000,
      },
      fees: {
        DMV_Tax_total: 0,
        DMV_Taxes: {},
        DMV_Fee_total: 0,
        DMV_Fees: {},
        DT_Tax_total: 0,
        DT_Taxes: {},
        DT_Fee_total: 0,
        DT_Fees: {
          "Dealertrack Fee": 0,
        },
      },
    };

    transaction.transaction_id = `${ts}_${counter++}`;
    transaction.vehicle.vin = vehicle.VIN;
    transaction.vehicle.model_year = vehicle.ModelYear;
    transaction.vehicle.weight_gross = vehicle.WeightGross;
    transaction.vehicle.weight_unladen = vehicle.WeightUnladen;
    transaction.vehicle.fair_market_value = vehicle.FairMarketValue;
    transaction.vehicle.year_first_sold = vehicle.YearFirstSold;
    transaction.vehicle.fuel_type = vehicle.FuelType;
    transaction.vehicle.bodystyle = vehicle.Body;

    transaction.client.client_zip = location.zip;
    transaction.client.client_city = location.city;
    transaction.client.client_state = state;

    transaction.party_owner.party_zip = location.zip;
    transaction.party_owner.party_city = location.city;

    transaction.party_owner.party_county = location.county;
    transaction.party_owner.party_state = state;

    filedata.transactions.push(transaction);
  });
  return filedata;
};

export default generateByState;

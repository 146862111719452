import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";
import { ReactFlowProvider } from "reactflow";

if (process.env.NODE_ENV !== "development") {
  window.console.log = function () {};
  window.console.error = () => {};
  window.console.debug = () => {};
}
window.REACT_APP_VERSION = process.env.REACT_APP_VERSION;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ProSidebarProvider>
      <ReactFlowProvider>
        <App />
      </ReactFlowProvider>
    </ProSidebarProvider>
  </BrowserRouter>
);

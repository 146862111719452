import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Stack,
  Table,
  Paper,
  TableContainer,
  TableRow,
  TableHead,
  Typography,
  useTheme,
  TableCell,
  TableBody,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import Bar from "../../components/Bar";

import API from "../../Api";
import { tokens } from "../../theme";
import ReactFlow, { Controls, Panel, ReactFlowProvider } from "reactflow";
import "reactflow/dist/style.css";
const Mapping = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = React.useState(false);
  const [codedvalues, setCodedvalues] = useState([]);
  const [feeslabels, setFeeslabels] = useState([]);

  const showProgress = () => {
    setOpen(true);
  };
  const hideProgress = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchCodedValueMapping();
  }, []);

  const fetchCodedValueMapping = async () => {
    try {
      showProgress();
      const r = await API.get(`/mappings/RTS/codedvalue`);
      console.log(r);
      setCodedvalues(r.data.codedValues);
      setFeeslabels(r.data.feesLabels);
    } catch (error) {
    } finally {
      hideProgress();
    }
  };

  return (
    <Box m="10px">
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} onClick={hideProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Typography variant="h3" align="center" color={colors.grey[200]} scope="row">
        Coded Value Source ➡️ Taget Mapping
      </Typography>

      {/* <ReactFlow className="react-flow-panel">
        <Controls showInteractive={false} />
        <Panel position="top-left">React Flow Fields Map</Panel>
      </ReactFlow> */}

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ fontSize: "1.0rem" }}>
                Source
              </TableCell>

              <TableCell align="left" sx={{ fontSize: "1.0rem" }}>
                State
              </TableCell>

              <TableCell align="left" sx={{ fontSize: "1.0rem" }}>
                Fuel Type
              </TableCell>
              <TableCell align="left" sx={{ fontSize: "1.0rem" }}>
                Vehicle Type
              </TableCell>

              <TableCell align="left" sx={{ fontSize: "1.0rem" }}>
                Timestamp
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {codedvalues.map((row) => (
              <TableRow key={row.SK} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell align="left">{row.PK.replace("SRC#", "").replace("#CV", "")}</TableCell>
                <TableCell align="left">{row.SK.replace("STATE#", "")}</TableCell>
                <TableCell align="left">{JSON.stringify(row.CodedMap.FuelType, null, 2)}</TableCell>
                <TableCell align="left">{JSON.stringify(row.CodedMap.VehicleType, null, 2)}</TableCell>
                <TableCell align="left">{new Date(row.Timestamp).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h3" align="center" color={colors.grey[200]} scope="row">
        Fees Label Mapping
      </Typography>

      <Typography variant="h6" align="center" color={colors.grey[200]} scope="row">
        Pipe (|) meaning combined. For example, in NC, 3 DMV fees adds up to total Title Fee. This is represented as
        "OOS Cert of Title|DMV Technical Improv|Mercury Bill Payable"
      </Typography>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ fontSize: "1.0rem" }}>
                Source
              </TableCell>

              <TableCell align="left" sx={{ fontSize: "1.0rem" }}>
                State
              </TableCell>

              <TableCell align="left" sx={{ fontSize: "1.0rem" }}>
                Mapping (Generic - DMV - RTS - PS - CARL)
              </TableCell>

              <TableCell align="left" sx={{ fontSize: "1.0rem" }}>
                Timestamp
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {feeslabels.map((row) => (
              <TableRow key={row.SK} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell align="left">{row.PK.replace("SRC#", "").replace("#FEESLABEL", "")}</TableCell>
                <TableCell align="left">{row.SK.replace("STATE#", "")}</TableCell>
                <TableCell align="left">
                  {Object.keys(row.FeesMap).map((item) => (
                    <>
                      <Typography key={item}>
                        {item} ➡️ {row.FeesMap[item].DMV} ➡️ {row.FeesMap[item].RTS} ➡️ {row.FeesMap[item].PS} ➡️{" "}
                        {row.FeesMap[item].CARL}
                      </Typography>
                      <hr></hr>
                    </>
                  ))}
                </TableCell>

                <TableCell align="left">{new Date(row.Timestamp).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Mapping;
